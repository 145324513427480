.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* App.css */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.app {

  margin: 0 auto;
  padding: 20px;
}

.overview, .podcasts, .contact-form {
  margin-bottom: 40px;
}

h1, h2, h3 {
  color: #333;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 20px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input, textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.app {
  background-color: #f5f5f5; /* Set your desired background color */
}

/* Navbar styles */
.navbar {
  background-color: #007bff; /* Set your desired Navbar background color */
}

.navbar-brand, .navbar-nav .nav-link {
  color: #ffffff !important; /* Set your desired text color */
}

.navbar-brand:hover, .navbar-nav .nav-link:hover {
  color: #ffffff !important; /* Set your desired text color on hover */
}

/* Overview section styles */
.overview {
  padding: 50px 20px;
  background-color: #ffffff; /* Set your desired background color */
  color: #333333; /* Set your desired text color */
}

/* Podcasts section styles */
.podcasts {
  padding: 50px 20px;
  background-color: #f5f5f5; /* Set your desired background color */
  color: #333333; /* Set your desired text color */
}

/* ContactForm section styles */
.contact-form {
  padding: 50px 20px;
  background-color: #ffffff; /* Set your desired background color */
  color: #333333; /* Set your desired text color */
}


.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.skeleton {
  background: linear-gradient(90deg, #eff1f3 25%, #e2e2e2 50%, #eff1f3 75%);
  background-size: 200% 100%;
  animation: loading 1s infinite;
}

.skeleton-title {
  width: 80%;
  height: 20px;
  margin-bottom: 10px;
}

@keyframes loading {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}